import React, { useEffect, useCallback, useState } from 'react';
import cn from 'classnames';
import Layout from 'components/common/Layout/Layout';
import Header from 'components/common/Header/Header';
import SectionHeading from 'components/common/SectionHeading/SectionHeading';
import Gap from 'components/common/Gap/Gap';
import styles from './AboutUs.module.scss';
import Text from 'components/common/Text/Text';
import { description } from './description';
import Button from 'components/common/Button/Button';
import { Row } from 'react-flexbox-grid';
import { ROUTES } from 'types/routes';

const texts = [
  'wiedza',
  'profesjonalizm',
  'indywidualne podejście',
  'poznaj nas',
];

const navigateToAllSpecializations = () => {
  if (typeof window !== 'undefined') {
    window.location.href = ROUTES.SPECIALIZATIONS;
  }
};

const navigateToTeam = () => {
  if (typeof window !== 'undefined') {
    window.location.href = ROUTES.TEAM;
  }
};

const AboutUs = () => {
  const [textVisible, setTextVisible] = useState(true);
  const animateText = useCallback(() => {
    setTextVisible(false);
    setTimeout(() => setTextVisible(true), 10);
  }, []);

  useEffect(() => {
    const animateInterval = setInterval(animateText, 16000);
    return () => {
      clearInterval(animateInterval);
    };
  }, []);
  return (
    <Layout>
      <Header logoClassName={styles.logo} />
      <div className={styles.picture}>
        {/* {textVisible &&
          texts.map((text, idx) => (
            <Text
              key={text}
              className={cn(styles.pictureText, styles[`pictureText${idx}`])}
              color="light"
              fontSize="3.5rem"
              weight="bold"
              transform="uppercase"
              alternativeFontFamily
              style={{
                animationDelay: `${idx * 3.5 + 1}s`,
              }}
            >
              {text}
            </Text>
          ))} */}
      </div>
      <Gap top="md" bottom="sm">
        <SectionHeading
          largeHeading="O nas"
          className={styles.heading}
          centered
        />
      </Gap>
      <Gap bottom="lg">
        <main className={styles.main}>{description}</main>
      </Gap>
      <Gap top="xxl" bottom="xxl">
        <div className={styles.buttons}>
          <Button
            type="neutral"
            onClick={navigateToAllSpecializations}
            className={styles.btn}
          >
            Oferta
          </Button>
          <Button
            type="neutral"
            onClick={navigateToTeam}
            className={cn(styles.btn, styles.teamBtn)}
          >
            Poznaj nasz Zespół
          </Button>
        </div>
      </Gap>
    </Layout>
  );
};

export default AboutUs;
