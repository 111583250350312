import React from 'react';
import Text from 'components/common/Text/Text';
import { Icon } from 'semantic-ui-react';
import Gap from 'components/common/Gap/Gap';

export const description = (
  <Text alternativeFontFamily fontSize="1.3rem" block>
    {`Mamy wieloletnie doświadczenie w obsłudze prawnej przedsiębiorców i jednostek samorządu terytorialnego, ale nie można nas nazwać „typowymi prawnikami”. Lubimy swoją pracę, a to z kolei przekłada się na wysoką jakość obsługi.
    
    Trudne życie polskiego przedsiębiorcy? Też je znamy. I wiemy, że kwestie prawne są jedną z tych rzeczy, które warto delegować. Chętnie zdejmiemy z Ciebie brzemię myślenia o kodeksach i paragrafach, żebyś mógł się skupić na tym, co robisz najlepiej. 
    
    Poza przedsiębiorcami obsługujemy także jednostki samorządu terytorialnego, ich organy i inne podmioty komunalne. Z naszego doświadczenia w tych dwóch pozornie wykluczających się dziedzinach powstała niepowtarzalna na rynku oferta. Zresztą nie od dziś znani jesteśmy z nieszablonowego podejścia. Możesz liczyć na to, że znajdziemy dla Ciebie unikalne rozwiązania i zawsze pokażemy Ci Twoją sytuację z kilku punktów widzenia. 
    
    Być może wydaje Ci się, że stała współpraca z kancelarią to zbyt poważna inwestycja. My za to jesteśmy przekonani, że zwróci Ci się wielokrotnie. I to właśnie dlatego, że traktujemy Cię bardzo poważnie, planując swój rozwój i podnosząc kwalifikacje z myślą o Twoich potrzebach. Może to sprawdzisz?
    
    Co o sobie mówimy?
    
    `}
    <ul>
      <li>
        <Text alternativeFontFamily fontSize="1.3rem" align="left" block>
          <Text weight="bold" fontSize="1.3rem">
            <Icon name="chevron right" />
            Twój sukces jest naszym celem.{' '}
          </Text>
          Nie możemy przewidzieć przyszłości, ale możemy Cię na nią przygotować.
        </Text>
      </li>
      <li>
        <Gap top="sm">
          <Text alternativeFontFamily fontSize="1.3rem" align="left" block>
            <Text weight="bold" fontSize="1.3rem">
              <Icon name="chevron right" />
              Dajemy z siebie wszystko.{' '}
            </Text>
            Cokolwiek robimy, zawsze staramy się, aby nasza praca była na
            najwyższym poziomie.
          </Text>
        </Gap>
      </li>
      <li>
        <Gap top="sm">
          <Text alternativeFontFamily fontSize="1.3rem" align="left" block>
            <Text weight="bold" fontSize="1.3rem">
              <Icon name="chevron right" />
              Upraszczamy skomplikowane.{' '}
            </Text>
            Prawo jest trudne, ale potrafimy przełożyć je na prosty język.
          </Text>
        </Gap>
      </li>
      <li>
        <Gap top="sm">
          <Text alternativeFontFamily fontSize="1.3rem" align="left" block>
            <Text weight="bold" fontSize="1.3rem">
              <Icon name="chevron right" />
              Wiemy, że pieniądze mają znaczenie.{' '}
            </Text>
            Nasze usługi są płatne, a Ty od początku wiesz co i ile kosztuje.
          </Text>
        </Gap>
      </li>
      <li>
        <Gap top="sm">
          <Text alternativeFontFamily fontSize="1.3rem" align="left" block>
            <Text weight="bold" fontSize="1.3rem">
              <Icon name="chevron right" />
              Jesteśmy elastyczni.{' '}
            </Text>
            Potrafimy reagować na zmiany, zanim jeszcze się o nich dowiesz.
          </Text>
        </Gap>
      </li>
      <li>
        <Gap top="sm">
          <Text alternativeFontFamily fontSize="1.3rem" align="left" block>
            <Text weight="bold" fontSize="1.3rem">
              <Icon name="chevron right" />
              Wykorzystujemy technologię.{' '}
            </Text>
            To pomaga zredukować koszty i podnieść jakość naszych usług.
          </Text>
        </Gap>
      </li>
    </ul>
  </Text>
);
