import React, { FC } from 'react';
import cn from 'classnames';
import {
  Button as SemanticButton,
  ButtonProps as SemanticButtonProps,
} from 'semantic-ui-react';
import styles from './Button.module.scss';

interface ButtonProps extends SemanticButtonProps {
  type?: 'primary' | 'neutral';
}

const Button: FC<ButtonProps> = props => (
  <SemanticButton
    {...props}
    className={cn(styles.btn, styles[props.type], props.className)}
  />
);

Button.defaultProps = {
  type: 'primary',
};

export default Button;
